import type { LinksFunction } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import React, { ReactNode, useEffect, useState } from "react";

import styles from "./styles/tailwind.css?url";
import datePickerStyles from "react-datepicker/dist/react-datepicker.css?url";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import NotFoundPage from "~/components/NotFoundPage";
import Card from "~/components/Card";
import SimpleNavbar from "~/components/SimpleNavbar";
import { Button, LinkButton } from "~/components/Button";
import EmptyCard from "~/components/EmptyCard";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      BETTERUPTIME_API_KEY: string;
      INTERCOM_API_SECRET: string;
      INTERCOM_HMAC_SECRET: string;
      NODE_ENV: "development" | "production" | "test";
      NODE_VERSION: string;
      STRIPE_API_RK: string;
      STRIPE_WEBHOOK_SIGNING_SECRET: string;
      SLACK_BILLING_TESTER_WEBHOOK: string;
      GH_APP_ID: string;
      GH_PRIVATE_KEY: string;
      GH_WEBHOOK_SECRET: string;
      GH_OAUTH_CLIENT_ID: string;
      GH_OAUTH_SECRET: string;
      SENTRY_AUTH_TOKEN: string;
      COOKIE_SECRET: string;
      SLACK_SIGNUP_WEBHOOK: string;
    }
  }
}
try {
  Object.defineProperty(BigInt.prototype, "toJSON", {
    get() {
      "use strict";
      return () => String(this);
    },
  });
} catch (e) {
  console.warn("Unable to define toJSON on BigInt.prototype");
}

export const IntercomWrapper = ({ children }: { children: ReactNode }) => {
  return <IntercomProvider appId="cnpisdzt">{children}</IntercomProvider>;
};

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: datePickerStyles },
  { rel: "stylesheet", href: "/fonts/circular/index.css" },
];

export function Layout({ children }: { children: ReactNode }) {
  return (
    <html lang="en">
      <head>
        <script
          data-domain="app.buildjet.com,all-sites.buildjet.com"
          data-api="https://thinkbeforeafter.cloudindex.workers.dev/api/occasion"
          src="https://thinkbeforeafter.cloudindex.workers.dev/js/playwright.js"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.plausible =
              window.plausible ||
              function () {
                (window.plausible.q = window.plausible.q || []).push(arguments);
              };
            `,
          }}
        />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function BaseHtml() {
  return (
    <IntercomProvider appId="cnpisdzt">
      <Outlet />
    </IntercomProvider>
  );
}

export function ErrorBoundary() {
  const [intercomIsPurged, setIntercomIsPurged] = useState(false);
  useEffect(() => {
    try {
      // The react intercom hook doesn't unmount/mount properly even though we do a hard shutdown
      // So we need to manually purge the intercom instance
      // @ts-ignore
      window.Intercom = null;
      // @ts-ignore
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "cnpisdzt",
      };

      setIntercomIsPurged(true);
    } catch (e) {
      console.error(e);
    }
  });
  return intercomIsPurged ? (
    <IntercomProvider appId="cnpisdzt">
      <ErrorBoundaryContent />
    </IntercomProvider>
  ) : null;
}

function ErrorBoundaryContent() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  const { boot, show } = useIntercom();

  useEffect(() => {
    try {
      boot();
      show();
    } catch (e) {
      console.error(e);
    }
  }, []);

  // @ts-ignore
  const errorMessage = error.data || error?.message || error.data?.error?.message;
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) return <NotFoundPage />;
  }


  return (
    <div className="grid h-full w-full grid-rows-headerBody">
      <SimpleNavbar
        actionElement={
          <div className="flex items-center gap-x-2">
            <LinkButton
              theme="blank"
              size="MD"
              text="Documentation"
              to="https://buildjet.com/for-github-actions/docs"
            />
            <form method="post" action="/logout">
              <Button theme="blank" size="MD" text="Log out" type="submit" />
            </form>
          </div>
        }
      />
      <div className="flex h-full items-center justify-center">
        <div className="w-full max-w-2xl">
          <EmptyCard
            IconElm={ExclamationTriangleIcon}
            headline="Oh no!"
            description="Something went wrong. Please try again later or contact support"
            BtnElm={
              <Card>
                <div>
                  <div className="flex items-center font-mono">
                    <div className="flex p-2 text-black">
                      <span className="text-sm">{errorMessage}</span>
                    </div>
                  </div>
                </div>
              </Card>
            }
          />
        </div>
      </div>
    </div>
  );
}
